<template>
  <section id="banner">
    <div class="parent">
      <v-container>
        <v-row>
          <v-col cols="6">
            <div class="content d-flex flex-column align-start">
              <h3><span>Huge saving</span> on <span>UHD Televisions</span></h3>
              <p>sale up to 70% on selected items</p>
              <button class="btn">shop now</button>
            </div>
          </v-col>
        </v-row>
      </v-container>
    </div>
  </section>
</template>

<style lang="scss">
  #banner {min-height:100vh;
           background-image:url(../../assets/images/banner.jpg);
           background-size:cover;
           background-position:left top;

   .parent {width:100%;
            height:fit-content;
            margin-top:10%;}


    .content {
        h3 {font-size:3.3rem;
            font-weight:bold;
            color:var(--white);
            text-align:left;
            
            @media (min-width:1200px) {width:80%;}

          span:first-child {color:var(--aqua3);}

        }

        p {color:var(--white);
           margin-top:1rem;
           margin-bottom:2rem;}

        .btn {text-transform:uppercase;}
    }
  }
</style>