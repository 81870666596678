<template>
  <section id="quality-features">
    <v-container>
      <v-row>
        <v-col v-for="card in cards" :key="card.title">
          <v-hover v-slot="{ isHovering, props }">
            <v-card v-bind="props" :elevation="isHovering ? 2 : 0" :color="isHovering ? 'blue-lighten-5' : 'white'" height="300" class="d-flex flex-column justify-center align-center" style="gap:0.5rem;padding:1rem;cursor:pointer;">
              <v-icon style="color:var(--violet9);font-size:3rem;">{{ card.icon }}</v-icon>
              <h5 style="font-weight:bold;text-transform:uppercase;">{{ card.title }}</h5>
              <p style="font-size:0.9rem;text-align:center;">{{ card.desc }}</p>
            </v-card>
          </v-hover>
        </v-col>
      </v-row>
    </v-container>
  </section>
</template>

<script>
export default {
    data: () => ({
        cards: [
            {title: "quality and saving",
             desc: "Comprehensive quality control and affordable prices",
             icon: "mdi-star-half-full",
            },
            {title: "global warehouse",
             desc: "37 overseas warhouses",
             icon: "mdi-warehouse",
            },
            {title: "fast shipping",
             desc: "fast and convenient door to door delivery",
             icon: "mdi-truck",
            },
            {title: "payment security",
             desc: "more than 10 different secure payment methods",
             icon: "mdi-security",
            },
            {title: "Have questions?",
             desc: "24/7 customer service - we're here and happy to help!",
             icon: "mdi-help-circle",
            },
        ],
    }),
};
</script>