<template>
  <section id="top-categories">
    <div class="section-heading">
      <h2 class="section-title">top categories</h2>
      <v-icon>mdi-star</v-icon>
    </div>
    <div class="page-container">
      <div class="category-items">
         <div class="category-item" v-for="cat in categories" :key="cat.title">
            <div class="image d-flex-r-c-c">
              <div class="image-thumbnail">
                <v-hover v-slot="{ isHovering, props }">
                  <img :src="cat.image" v-bind="props" :style="`cursor:pointer;transition:all 0.3s linear;scale:${isHovering ? 1.05 : 1};`">
                </v-hover>
              </div>
            </div>
            <h5>{{ cat.title }}</h5>
         </div>
      </div>
    </div>
  </section>
</template>

<script>
export default {
    data: () => ({
        categories: [
            {title:"phones",
             image:require("@/assets/images/categories/phones.png"),
            },
            {title:"electronics",
             image:require("@/assets/images/categories/4011095xw_1.png"),
            },
            {title:"shoes",
             image:require("@/assets/images/categories/34449481fdb_classic-shoes.jpg"),
            },
            {title:"sports shoes",
             image:require("@/assets/images/categories/26901909kc_basketball.png"),
            },
            {title:"womens slipers",
             image:require("@/assets/images/categories/42674981bm_sandal.jpg"),
            },
            {title:"sweat-shirts",
             image:require("@/assets/images/categories/44892173mz_switshirt.jpg"),
            },
            {title:"playstations",
             image:require("@/assets/images/categories/58140800udm_playstation5-2.png"),
            },
            {title:"catles",
             image:require("@/assets/images/categories/62042668ge_5.jpg"),
            },
            {title:"laptops",
             image:require("@/assets/images/categories/72626639llt_hp2.jpg"),
            },
            {title:"cases",
             image:require("@/assets/images/categories/74146815dxm_2.png"),
            },
            {title:"car accessories",
             image:require("@/assets/images/categories/caraccessories.jpeg"),
            },
        ],
    }),
};
</script>

<style lang="scss">
#top-categories {
   .page-container {
        .category-items {display:grid;
                         grid-template-columns:repeat(auto-fill,minmax(170px, 1fr));
                         gap:1.3rem;
                         margin-bottom:2rem;

            .category-item {width:100%;
                            display:block;
                            text-align:center;
                            padding:0;
                            border-radius:0;
                            box-shadow:none;
                            background-color:transparent;
                            position:relative;

                .image {width:8rem;
                        height:8rem;
                        border-radius:50%;
                        margin:0 auto 1rem;
                        background-color:var(--gray7);

                    .image-thumbnail {width:65%;
                                      height:65%;
                                      overflow:hidden;
                                      border-radius:50%;

                        img {width:100%;
                             height:100%;
                             text-align:center;
                             border-radius:50%;
                             object-fit:cover;
                             transition:all 0.3s linear;}
                    }
                }

                h5 {margin:0.4rem 0;}
            }
        }
    }
}
</style>