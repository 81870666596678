<template>
  <section id="shop-withus">
      <v-row>

        <v-col md="3" sm="6" cols="12">
          <v-card elevation="0">
            <img src="@/assets/images/parachute.png">
            <v-card-title>free shipping on first order</v-card-title>
            <p>Lorem ipsum dolor sit amet.</p>
            <a href="#">learn more</a>
          </v-card>
        </v-col>

        <v-col md="3" sm="6" cols="12">
          <v-card elevation="0" class="text-center">
            <img src="@/assets/images/flash3.png">
            <v-card-title>weekly flash sale</v-card-title>
            <p>Lorem ipsum dolor sit amet.</p>
            <a href="#">learn more</a>
          </v-card>
        </v-col>

        <v-col md="3" sm="6" cols="12">
          <v-card elevation="0" class="text-center">
            <img src="@/assets/images/calendar.png">
            <v-card-title>annual payment discount</v-card-title>
            <p>Lorem ipsum dolor sit amet.</p>
            <a href="#">learn more</a>
          </v-card>
        </v-col>

        <v-col md="3" sm="6" cols="12">
          <v-card elevation="0" class="text-center">
            <img src="@/assets/images/cashback.png">
            <v-card-title>cashback reword program</v-card-title>
            <p>Lorem ipsum dolor sit amet.</p>
            <a href="#">learn more</a>
          </v-card>
        </v-col>

      </v-row>
  </section>
</template>

<style lang="scss">
#shop-withus {background-image:url(../../assets/images/banner.jpg);
              background-size:cover;
              background-repeat:repeat-x;

  .v-card {background-color:transparent;
    img {width:5rem;}

    .v-card-title {white-space:pre-wrap;
                   font-weight:700;
                   color:var(--white);
                   text-transform:capitalize;}

    p {color:var(--white);
       text-align:center;
       margin:0 0 0.2rem;}

    a {color:var(--white);
       font-size:0.9rem;
       font-weight:bold;
       margin:0 !important;}
  }

  @media (max-width:959px){.v-card {margin-bottom:1rem;}}
}
</style>