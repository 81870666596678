<template>
  <v-app-bar color="#014ba0" id="fixed-header">
    <v-container fluid>
      <v-row>

        <v-col cols="7" class="d-flex flex-row justify-center align-center">
          <ul class="links d-flex flex-row justify-space-between w-100">
            <li v-for="category in categories" :key="category.title">
              <router-link :to="{ name: 'categorypage', query: { category: category.route, title: category.title }}">{{ category.title }}</router-link>
            </li>
          </ul>
        </v-col>

        <v-col cols="5" class="d-flex flex-row justify-end align-center" style="gap:0.5rem;">
          <div class="wishlists d-flex flex-column align-center" :style="`cursor:pointer;pointer-events:${$route.name === 'cartpage' ? 'none' : 'unset'}`" @click="toggleCart">
            <v-badge location="right top" color="blue" offsetX="-12" style="z-index:2;" :content="cartItems.length" v-if="cartItems.length"></v-badge>
            <v-icon>mdi-cart-outline</v-icon>
            <span>cart</span>
          </div>
        </v-col>

      </v-row>
    </v-container>
  </v-app-bar>
</template>

<script>
import { productsModule } from "@/stores/products";
import { mapState } from "pinia";
import { cartStore } from "@/stores/cart";

export default {
  inject: ["Emitter"],
  methods: {
    toggleCart(){
      this.Emitter.emit("toggleCart");
    },
  },
  computed: {
    ...mapState(productsModule, ["categories"]),
    ...mapState(cartStore, ["cartItems"]),
  },
};
</script>