import { createRouter, createWebHistory } from "vue-router";
import HomeView from "@/views/HomeView.vue";

const routes = [
  {
   path: "/",
   name: "home",
   component: HomeView,
  },
  {
   path: "/login",
   name: "loginpage",
   component: () => import("@/views/LoginPage"),
   },
  {
   path: "/products",
   name: "categorypage",
   component: () => import("@/views/CategoryPage"),
  },
  {
   path: "/products/product/:productId",
   name: "singlepage",
   component: () => import("@/views/SinglePage"),
  },
  {
   path: "/cart-page",
   name: "cartpage",
   component: () => import("@/views/CartPage"),
  },
  {
   path: "/checkout",
   name: "checkout",
   component: () => import("@/views/CheckOut"),
  },
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
  scrollBehavior() {
    return { top: 0 };
  },
});

export default router;
