<template>
  <v-layout class="position-relative">
    <CardDrawer />
    <AppNav />
    <FixedNav />
    <v-main style="padding-top:7.5rem;">
      <slot></slot>
    </v-main>
    <AppFooter />
  </v-layout>
</template>

<script>
import AppNav from "./AppNav";
import FixedNav from "./FixedNav";
import CardDrawer from "./CardDrawer";
import AppFooter from "./AppFooter";

export default {
 data: () => ({
    drawer:false,
 }),
 components: {
  AppNav, CardDrawer, AppFooter, FixedNav
 },
};
</script>