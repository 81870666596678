<template>
  <v-navigation-drawer location="right" temporary v-model="drawer" width="370" style="padding:0.5rem 0.2rem 0.5rem 1rem;">
    <v-card elevation="0" style="height:19%;">
      <div class="title-area d-flex-r-bt-c">
        <v-card-title class="px-0" style="font-size:1.2rem;">Shopping Card</v-card-title>
        <v-icon style="font-size:1.2rem;" @click="drawer = false">mdi-close</v-icon>
      </div>
      <p style="line-height:0.5rem;text-align:left;">{{ cartItems.length }} item</p>
      <div class="bar-block mt-3 position-relative" v-if="cartItems.length > 0"> <!-- BAR -->
        <v-icon :style="`left:calc(${parseInt((calculateTotal / 5000) * 100) <= 100 ? parseInt((calculateTotal / 5000) * 100) : 100}% - 1.5rem);`">mdi-truck-fast</v-icon>
        <v-progress-linear :color="parseInt((calculateTotal / 5000) * 100) < 50 
                                       ? 'var(--orange-red1)'
                                       : parseInt((calculateTotal / 5000) * 100) > 50 && parseInt((calculateTotal / 5000) * 100) < 100
                                       ? 'orange'
                                       : 'green'" 
            height="10" :model-value="parseInt((calculateTotal / 5000) * 100) <= 100 ? parseInt((calculateTotal / 5000) * 100) : 100" striped style="border-radius:0.5rem;"></v-progress-linear>
      </div>
      <p class="text-left" style="color:var(--gray4);" v-if="5000 - calculateTotal > 0">only ${{ 5000 - calculateTotal }} away from free shipping</p>
      <p class="text-left" style="color:var(--gray4);" v-if="5000 - calculateTotal <= 0">your order now included free shipping</p>
    </v-card>
    <v-card elevation="0" class="text-left" v-if="!cartItems.length">
      <p class="py-1" style="color:var(--gray4)">free shipping for all orders over $5000</p>
      <p class="py-1" style="color:var(--gray4)">your cart is empty</p>
      <v-card-actions>
        <v-btn class="w-100 mt-3 rounded-lg" height="45" variant="outlined" density="compact" @click="drawer = false">Continue Shopping</v-btn>
      </v-card-actions>
    </v-card>
    <v-card elevation="0" class="products-card" v-if="cartItems.length > 0" style="height:81% !important;"> <!-- Products -->
      <v-container>
        <v-row v-for="item in cartItems" :key="item.id"> <!-- Product -->
          <v-col cols="4"><img :src="item.thumbnail" class="w-100" alt=""></v-col>
          <v-col cols="8" class="text-left">
            <v-card-title class="card-title">{{ item.title }} </v-card-title>
            <p style="line-height:1.4rem;color:var(--gray4);">red / xxl</p>
            <p class="font-weight-bold mb-1" style="line-height:1.4rem;">${{ Math.ceil(item.price - item.price * (item.discountPercentage / 100)) }}</p>
            <div class="product-footer d-flex-r-bt-c">
              <div class="product-quantity d-flex-r-st-c"> <!-- button -->
                <button type="button" class="pro-quantity-btn d-flex-r-c-c" @click="decrementQuantity(item)"><v-icon>mdi-minus</v-icon></button>
                <span id="pro-quantity-no">{{ item.quantity }}</span>
                <button type="button" class="pro-quantity-btn d-flex-r-c-c" @click="incrementQuantity(item)"><v-icon>mdi-plus</v-icon></button>
              </div>
              <v-icon size="18" style="font-weight:bold !important;" @click="deleteItem(item.id)">mdi-close</v-icon>
            </div>
          </v-col>
        </v-row>
        <div class="icons d-flex-r-c-c w-100"> <!-- icons -->
          <v-icon>mdi-clipboard-text-outline</v-icon>
          <v-icon>mdi-gift-outline</v-icon>
          <v-icon>mdi-truck-fast</v-icon>
          <v-icon>mdi-label-outline</v-icon>
        </div>
        <div class="result-block d-flex-c-st-st w-100" style="gap:0.3rem;"> <!-- all-price -->
          <div class="result-row d-flex-r-bt-c w-100"><span>subtotal:</span> <p class="subtotal">${{ calculateSubtotal }}</p></div>
          <div class="result-row d-flex-r-bt-c w-100"><span>total:</span> <p>${{ calculateTotal }}</p></div>
        </div>
        <v-card-text style="padding:0.9rem 0 0.3rem;color:var(--gray3);">Tax included & shipping calculated at checkout</v-card-text>
        <div class="agree-terms d-flex-r-st-c" style="gap:0.4rem;padding:0.3rem 0 1.3rem;">
          <input type="checkbox" id="agree-terms"> <!-- agree-terms -->
          <label for="agree-terms" style="font-size:0.9rem;text-transform:none;">I agree with terms & conditions</label>
        </div>
        <v-card-actions class="d-flex-c-c-c px-0 py-0"> <!-- checkout -->
          <button class="btn2 w-100 mb-2 rounded-xl" @click="toCheckout">checkout</button>
          <button class="trans-btn w-100 rounded-xl" @click="$router.push({name:'cartpage'})">view card</button>
        </v-card-actions>
      </v-container>
    </v-card>
  </v-navigation-drawer>
</template>

<script>
import { cartStore } from "@/stores/cart";
import { mapActions, mapState } from "pinia";

export default {
  inject: ["Emitter"],
  data: () => ({
    drawer: false,
  }),
  methods: {
    ...mapActions(cartStore, ["getCartItems", "setToLocalStorage", "deleteItem"]),
    decrementQuantity(item) {
        if (item.quantity > 1) {
            item.quantity--;
        }
    },
    incrementQuantity(item) {
        if (item.quantity < item.stock) {
            item.quantity++;
        }
    },
    toCheckout(){
      this.setToLocalStorage();
      this.$router.push({ name: "checkout" });
    },
  },
  computed: {
    ...mapState(cartStore, ["cartItems"]),
    calculateSubtotal() {
      return this.cartItems.reduce((total, item) => {
        return total + (Math.ceil(item.price - item.price * (item.discountPercentage / 100)) * item.quantity);
      }, 0);
    },
    calculateTotal() {
      return this.calculateSubtotal;
    }
  },
  mounted(){
    this.Emitter.on("toggleCart", () => {
      this.drawer = !this.drawer;
    });
    this.getCartItems();
  },
};
</script>

<style lang="scss">
.v-navigation-drawer__content {
    &::-webkit-scrollbar-track {width:4px;
                                background-color:var(--gray8);}

    &::-webkit-scrollbar {width:4px;}

    &::-webkit-scrollbar-thumb {background-image:linear-gradient(125deg, var(--shin-blue3) 0%, var(--shin-orange) 100%);
                                border-radius:1rem;}
}

.v-card {padding:0 0.9rem 0 0 !important;}

.bar-block {
  .v-icon {position:absolute;
           bottom:50%;
           font-size:1.5rem;
           color:var(--orange-red1) !important;
           z-index:1;
           transition:all 0.2s linear;}
}

.products-card {text-align:left !important;
                height:87% !important;
                overflow-y:auto !important;

    &::-webkit-scrollbar-track {width:4px;
                                background-color:var(--gray8);}

    &::-webkit-scrollbar {width:4px;}

    &::-webkit-scrollbar-thumb {background-image:linear-gradient(125deg, var(--shin-blue3) 0%, var(--shin-orange) 100%);
                                border-radius:1rem;}
}

.card-title {font-size:1rem !important;
             font-weight:bold;
             padding:0 !important;
             margin:0 !important;
             line-height:1.4rem !important;}

.product-quantity {width:fit-content;
                   margin:0.1rem 0 0;
                   gap:0.3rem;
                   border-radius:1.5rem;
                   border:1.5px solid var(--gray5);

   .pro-quantity-btn {padding:0.3rem 0.2rem;
                      font-size:0.8rem;
                      color:var(--gray5);
                      background-color:transparent;
                      cursor:pointer;}

   #pro-quantity-no {font-size:1rem;
                     margin:0;
                     padding:0;
                     color:var(--gray1);}

}

.result-block {gap:0.1rem;
  .result-row {
    span {font-size:0.9rem;
          font-weight:1000;}
    p {width:fit-content;
       font-weight:bold;}

    p.subtotal {font-size:0.8rem !important;}
  }
}

.icons {margin:1.5rem 0;
  .v-icon {font-size:1.5rem;
           padding:1.4rem 2.3rem;
           color:var(--gray2);
           border:1px solid var(--gray7);}

  .v-icon:first-child,
  .v-icon:nth-child(2),
  .v-icon:nth-child(3) {border-right-style:none}

  .v-icon:first-child {border-top-left-radius:0.3rem;
                       border-bottom-left-radius:0.3rem;}

  .v-icon:last-child {border-top-right-radius:0.3rem;
                      border-bottom-right-radius:0.3rem;}
}
</style>