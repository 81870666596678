<template>
  <section id="features">
    <v-container class="d-flex flex-row justify-center align-center" style="flex-wrap:wrap;">
        <div class="card" style="flex:1 1 20rem;">
          <v-icon>mdi-truck-fast</v-icon>
          <span>Free Shipping & Returns</span>
        </div>
        <div class="line"></div>
        <div class="card" style="flex:1 1 20rem;">
          <v-icon>mdi-seal-variant</v-icon>
          <span>Lowest Price Garantee</span>
        </div>
        <div class="line last-line"></div>
        <div class="card" style="flex:1 1 20rem;">
          <v-icon>mdi-trophy</v-icon>
          <span>Longest Warranties Offer</span>
        </div>
    </v-container>
  </section>
</template>

<style lang="scss">
#features {background-color:var(--white);

  .card {color:var(--black);

       i {margin-right:0.5rem;}

       span {font-weight:bold;}

  }
  
  @media(max-width:1060px){.card {margin-bottom:1rem;}}

  .line {width:1.7px;
         height:1.3rem;
         margin:0;
         background-color:var(--black);}

  @media(max-width:1060px){.line {margin:-0.2rem 0.2rem 0.8rem;}
                           .line.last-line {display:none;}
  }

  @media(max-width:7014px){.line {display:none;}}
}
</style>