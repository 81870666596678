<template>
  <section id="top-offers">
    <v-container>
      <v-row>

        <v-col md="4" sm="6" cols="12" class="card">
          <img src="@/assets/images/pc1.jpg">
        </v-col>

        <v-col md="4" sm="6" cols="12" class="card">
          <img src="@/assets/images/playstation3.jpg">
        </v-col>

        <v-col md="4" sm="6" cols="12" class="card">
          <img src="@/assets/images/PlayStation1.jpg">
        </v-col>

        <v-col sm="6" cols="12" class="card2">
          <img src="@/assets/images/gamer2.jpg">
        </v-col>

        <v-col sm="6" cols="12" class="card2">
          <img src="@/assets/images/pc2.jpg">
        </v-col>

      </v-row>
    </v-container>
  </section>
</template>

<style lang="scss">
#top-offers .v-container .v-row img {width:100%;
                                     object-fit:cover;
                                     border-radius:0.3rem;}

#top-offers .v-container .v-row .card img {height:18rem;}

#top-offers .v-container .v-row .card2 img {height:20rem;}
</style>