<template>
  <app-layout>
    <router-view />
    <QuickView />
    <v-snackbar v-model="snackbar" location="center top" max-width="300" timeout="3000">
       {{ itemTitle }} has been added to your cart successfully!
      <template v-slot:actions>
        <v-icon @click="snackbar = false">mdi-close</v-icon>
      </template>
    </v-snackbar>
  </app-layout>
</template>

<script>
import AppLayout from "@/components/global/AppLayout";
import QuickView from "@/components/global/QuickView";

export default {
  inject: ["Emitter"],
  components: {
    AppLayout,
    QuickView,
  },
  data: () => ({
    snackbar: false,
    itemTitle:"",
  }),
  mounted(){
    this.Emitter.on("showMsg", (data) => { 
       this.itemTitle = data;
       this.snackbar = true;
    });
  },
};
</script>

<style lang="scss">
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
}

nav {
  padding: 30px;

  a {
    font-weight: bold;
    color: #2c3e50;

    &.router-link-exact-active {
      color: #42b983;
    }
  }
}
</style>
