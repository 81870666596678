<template>
  <UpperBanner />
  <TheFeatures />
  <TopOffers />
  <ProductsComponent :products="flashDeals" sectionTitle="flash deals" :index="1"/>
  <TopCats />
  <QualityFeatures />
  <ProductsComponent :products="mobilePhones" sectionTitle="top mobile phones" :index="0"/>
  <ProductsComponent :products="fragrances" sectionTitle="fragrances" :index="2"/>
  <WhyShopwithus />
</template>

<script>
import { productsModule } from "@/stores/products";
import { mapActions, mapState } from "pinia";
import UpperBanner from "@/components/home_page/UpperBanner";
import TheFeatures from "@/components/home_page/TheFeatures";
import TopOffers from "@/components/home_page/TopOffers";
import ProductsComponent from "@/components/home_page/ProductsComponent";
import TopCats from "@/components/home_page/TopCategories";
import QualityFeatures from "@/components/home_page/QualityFeatures";
import WhyShopwithus from "@/components/home_page/WhyShopwithus";

export default {
  name: "HomeView",
  components: {
    UpperBanner,
    TheFeatures,
    TopOffers,
    ProductsComponent,
    TopCats,
    QualityFeatures,
    WhyShopwithus,
  },
  methods: {
    ...mapActions(productsModule, ["getProducts"]),
  },
  computed: {
     ...mapState(productsModule, ["flashDeals", "mobilePhones", "fragrances"]),
  },
  async mounted(){
    await this.getProducts();
  },
};
</script>
