import { defineStore } from "pinia";
import axios from "axios";

export const productsModule = defineStore('productsModule', ({
    state:() => ({
        flashDeals:[],
        mobilePhones:[],
        fragrances:[],
        categoryProducts:[],
        singleProduct: "",
        categories: [
            {title:"Smart Phones", route: "smartphones",},
            {title:"Laptops", route: "laptops",},
            {title:"fragrances", route: "fragrances",},
            {title:"Mens Shoes", route: "mens-shoes",},
            {title:"Mens Watches", route: "mens-watches",},
            {title:"Women's Bags", route: "womens-bags",},
            {title:"Motorcycle", route: "motorcycle",},
        ],
    }),
    actions: {
        async getProducts(){
            await axios.get('https://dummyjson.com/products')
               .then((res) => {
                  this.flashDeals = res.data.products.slice(0, 22);
                  this.mobilePhones = res.data.products.filter((el) => el.category === "smartphones");
                  this.fragrances = res.data.products.filter((el) => el.category === "fragrances");
               });
        },
        async getProductsByCategory(category){
           await axios.get(`https://dummyjson.com/products/category/${category}`)
                      .then((res) => (this.categoryProducts = res.data));
        },
        async getSingleProduct(productId){
            await axios.get(`https://dummyjson.com/products/${productId}`)
                       .then((res) => (this.singleProduct = res.data));
        },
    },
}));