<template>
  <section id="flash-deals">
    <div class="section-heading">
      <h2 class="section-title">{{ sectionTitle }}</h2>
      <v-icon>mdi-star</v-icon>
    </div>
    <router-link :to="{name: 'categorypage', 
                        query: {
                          title: categories[index].title, 
                          category: categories[index].route,
                      },}">
      shop all
    </router-link>
    <v-container fluid v-if="!products.length">
      <v-row>
        <v-col cols="12" v-if="!products.length">
          <v-row>
            <v-col cols="3" v-for="num in 4" :key="num">
              <v-skeleton-loader type="image, article, button"></v-skeleton-loader>
            </v-col>
          </v-row>
        </v-col>
      </v-row>
    </v-container>
    <Swiper :pagination="{el:'.swiper-pagination', clickable:true}" :modules="modules" :slides-per-view="4" :space-between="30" :navigation="{ prevIcon: '.swiper-prev', nextIcon: '.swiper-next' }" :autoplay="{ delay: 4000, pauseOnMouseEnter: true, disableOnInteraction: false }" :loop="true" style="padding:0 1.8rem 2rem;">
      <swiper-slide v-for="item in products" :key="item.id">
        <div class="card">
          <v-hover v-slot="{ isHovering, props }">
            <div class="thumbnail_image position-relative">
              <img :src="showenItem[item.title] ? showenItem[item.title] : item.thumbnail" class="thumbnail" :style="`transition:0.3s all linear;scale:${isHovering ? 1.05 : 1};`" v-bind="props">
              <button density="compact" class="quick-view-btn" @click="openQuickView(item)">Quick View</button>
            </div>
          </v-hover>
          <div class="content d-flex flex-column justify-start align-start" style="gap:0.4rem;">
            <h4>{{ `${(item.title )} ${item.description}`.length<=55
                   ? `${(item.title )} ${item.description}`
                   : `${(item.title )} ${item.description}`.substring(0, 55) + " ..."
                }}
            </h4>
            <v-rating v-model="item.rating" density="compact" color="yellow-darken-2" half-increments readonly></v-rating>
            <div class="price">
              <del>${{ item.price }}</del>
              <strong>${{ Math.ceil(item.price - item.price * (item.discountPercentage / 100)) }}</strong>
            </div>
            <v-btn-toggle v-model="showenItem[item.title]" mandatory>
              <v-btn v-for="(pic, i) in item.images" :value="pic" :key="i" size="x-small">
                <img :src="pic" width="30" height="30" style="border-radius:50%;">
              </v-btn>
            </v-btn-toggle>
            <button class="trans-btn" @click="$router.push({ name: 'singlepage', params: {productId: item.id}, })">choose options</button>
          </div>
        </div>
      </swiper-slide>
      <div class="swiper-prev"></div>
      <div class="swiper-next"></div>
      <div class="swiper-pagination"></div>
    </Swiper>
  </section>
</template>

<script>
import { Swiper, SwiperSlide } from "vue-awesome-swiper";
import { Pagination, Navigation, Autoplay } from "swiper";
import { VSkeletonLoader } from "vuetify/lib/components/index.mjs";
import { productsModule } from "@/stores/products";
import { mapState } from "pinia";

export default {
  inject: ['Emitter'],

  methods: {
    openQuickView(product){
       this.Emitter.emit("openQuickView", product);
    },
  },
  computed: {
    ...mapState(productsModule, ["categories"]),
  },
  props: {
    products: {
      type: Array,
    },
    title: {
      type: String,
    },
    sectionTitle: {
      type: String,
    },
    index: {
      type: Number,
    },
  },
  setup() {
    return{
      modules: [Pagination, Navigation, Autoplay],
    };
  },
  components: {
    Swiper, SwiperSlide, VSkeletonLoader,
  },
  data: () => ({
     showenItem: {},
  }),
};
</script>

<style lang="scss">
#flash-deals {
  .swiper-pointer-events {padding-bottom:3rem !important;

    .swiper-button-prev {left:0;}
    .swiper-button-next {right:0;}

    .swiper-button-next, 
    .swiper-button-prev {width:2rem;
                         height:2rem;
                         border:2px solid var(--black);
                         border-radius:50%;
                         background-color:var(--black);
                         top:35%;
                          &::after {font-size:1rem;
                                    padding:0;
                                    display:flex;
                                    justify-content:center;
                                    align-items:center;
                                    color:var(--white);
                                    font-weight:bold;
                          }
                        }
    .swiper-pagination {bottom:0 !important;
                        
      .swiper-pagination-bullet {width:0.7rem;
                                 height:0.7rem;}
    }
  }

  .card {
    .thumbnail_image {height:12rem;
                      width:90%;
                      margin:0 auto;
                      overflow:hidden;

      .thumbnail {height:100%;
                  width:100%;
                  cursor:pointer;
                  object-fit:cover;}

      .quick-view-btn {position:absolute;
                       top:50%;
                       left:50%;
                       padding:0.4rem 0.8rem;
                       color:var(--gray1);
                       background-color:var(--white2);
                       box-shadow:var(--box-shadow2);
                       border-radius:2rem;
                       font-size:0.9rem;
                       font-weight:bold;
                       opacity:0;
                       transform:translate(-50%,-50%);}

    }
    .thumbnail_image:hover .quick-view-btn {opacity:1;}
    @media(min-width:370px) and (max-width:509px){.thumbnail_image {width:60%}}

    .content {width:90%;
              margin:0 auto;

        h4 {font-size:0.9rem;
            text-align:left;
            margin-top:1rem;}

        .v-icon {font-size:1.2rem;
                 margin:0 !important;}

        .price {display:flex;
                flex-direction:row;
                justify-content:center;
                align-items:center;
                gap:1rem;

            del {font-size:0.9rem;
                 margin-right:1rem;
                 text-decoration:line-through !important;}
  
            strong {font-size:1rem;
                    color:var(--orange-red);}
        }

        .v-btn-group {background-color:transparent;
          .v-btn {background-color:transparent;}
        }

        .trans-btn {width:80%;
                    margin:0.5rem auto 0;
                    border-radius:1.5rem;}
        
    }
    @media(min-width:370px) and (max-width:509px){.content {width:60%}}

  }

}
</style>