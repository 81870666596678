<template>
  <v-footer :app="true" absolute class="d-block">
    <v-container>
      <v-row>
        <v-col md="3" sm="6" cols="12">
          <v-card elevation="0">
            <v-card-title>shop</v-card-title>
            <v-card-text v-for="category in categories" :key="category.title">
              <router-link :to="{ name: 'categorypage', query: { category: category.route, title: category.title }}">{{ category.title }}</router-link>
            </v-card-text>          
          </v-card>
        </v-col>
        <v-col md="3" sm="6" cols="12">
          <v-card elevation="0">
            <v-card-title>further info</v-card-title>
            <v-card-text class="py-2">about</v-card-text>
            <v-card-text class="py-2">customer service</v-card-text>
            <v-card-text class="py-2">reward program</v-card-text>
            <v-card-text class="py-2">shipping & returns</v-card-text>
            <v-card-text class="py-2">privacy policy</v-card-text>
            <v-card-text class="py-2">terms & conditions</v-card-text>
            <v-card-text class="py-2">blog</v-card-text>
          </v-card>
        </v-col>
        <v-col md="3" sm="6" cols="12">
          <v-card elevation="0">
            <v-card-title>customer service</v-card-title>
            <v-card-text class="py-2">search terms</v-card-text>
            <v-card-text class="py-2">advanced search</v-card-text>
            <v-card-text class="py-2">orders & returns</v-card-text>
            <v-card-text class="py-2">contact us</v-card-text>
            <v-card-text class="py-2">Theme FAQs</v-card-text>
            <v-card-text class="py-2">consultant</v-card-text>
            <v-card-text class="py-2">store locations</v-card-text>
          </v-card>
        </v-col>
        <v-col md="3" sm="6" cols="12" class="last-col">
          <v-card elevation="0">
            <img src="@/assets/images/brand/brand6.png" alt="">
            <div class="contacts">
              <v-card-text class="py-2"><v-icon>mdi-map-marker</v-icon> united states, los angelos</v-card-text>
              <v-card-text class="py-2"><v-icon>mdi-phone</v-icon>call us at (489) 832-98430</v-card-text>
              <v-card-text class="py-2"><v-icon>mdi-email</v-icon>example@domain.com</v-card-text>
            </div>
            <ul class="social d-flex flex-row justify-start align-center">
              <li><a href="#" class="facebook"><v-icon>mdi-facebook</v-icon></a></li>
              <li><a href="#" class="pinterest"><v-icon>mdi-pinterest</v-icon></a></li>
              <li><a href="#" class="instagram"><v-icon>mdi-instagram</v-icon></a></li>
              <li><a href="#" class="linkedin"><v-icon>mdi-linkedin</v-icon></a></li>
              <li><a href="#" class="youtube"><v-icon>mdi-twitter</v-icon></a></li>
            </ul>
          </v-card>
        </v-col>
        <v-col cols="12">
          <div class="corporations-image d-flex flex-row justify-center align-center">
            <img src="@/assets/images/norton.png" alt="">
            <img src="@/assets/images/TRUSTe.jpg" alt="">
            <img src="@/assets/images/authorizenet.png" alt="">
            <img src="@/assets/images/visa.png" alt="">
            <img src="@/assets/images/mastercard.png" alt="">
            <img src="@/assets/images/accredited.png" alt="">
          </div>
        </v-col>
      </v-row>
    </v-container>
    <v-container fluid class="bottom-footer">
      <v-row>
        <v-col cols="6">
          <p>{{ new Date().getFullYear() }} arty. all rights reserved powered by arty</p>
          <p>arty themes by arty.com</p>
        </v-col>
        <v-col cols="6">
          <div class="visa-cards d-flex flex-row justify-end">
            <img v-for="(card, index) in visaCards" :key="index" :src="card" alt="">
          </div>
        </v-col>
      </v-row>
    </v-container>
  </v-footer>
</template>

<script>
import { productsModule } from "@/stores/products";
import { mapState } from "pinia";

export default {
  data() {
    return {
      visaCards: [
        require('@/assets/images/cards/visa.png'),
        require('@/assets/images/cards/mastercard.png'),
        require('@/assets/images/cards/applepay.png'),
        require('@/assets/images/cards/paypal.png'),
        require('@/assets/images/cards/opay.png'),
      ]
    }
  },
  computed: {
    ...mapState(productsModule, ["categories"]),
  },
}
</script>

<style lang="scss">
footer {background-color:var(--blue) !important;
        padding:1rem 0 0 !important;

  .v-card {text-align:left;
           background-color:transparent;

    .v-card-title {text-transform:uppercase;
                   font-weight:bold;
                   color:var(--gray2);}

    .v-card-text {text-transform:capitalize;
                  color:var(--gray3);
      a {color:var(--gray3);}
    }

    .contacts {
      .v-icon {margin-right:0.5rem;
               color:var(--gray1);}
    }

    .social {gap:0.7rem;
             margin-top:0.8rem;
             padding-left:0.9rem;
      li {display:flex;
          justify-content:center;
          align-items:center;
        a {display:flex;
           justify-content:center;
           align-items:center;
           text-align:center;
           width:2.4rem;
           height:2.4rem;
           margin:0 !important;
           border-radius:50%;
           font-size:1rem;
           background-color:var(--gray2);
          .v-icon {color:var(--white);
                   margin:0 !important;}
        }
        a:hover {background-color:var(--gray7);}
        a.facebook:hover .v-icon {color:var(--blue7);}
        a.pinterest:hover .v-icon {color:var(--orange-red1);}
        a.instagram:hover .v-icon {color:var(--primary-color1);}
        a.linkedin:hover .v-icon {color:var(--shin-blue5);}
        a.youtube:hover .v-icon {color:var(--orange-red1);}
      }
    }

  }

  .corporations-image {padding:2rem 0;
                       gap:1.5rem;
    img {height:1.9rem;}
  }

  .visa-cards {gap:1rem;
    img {height:2.4rem;}
  }

  .bottom-footer {background-color:var(--white);
                  padding:1.3rem 0.9rem;
    p {color:var(--gray4);
       margin-bottom:0.7rem;
       font-size:15px;}
  }

}
</style>
