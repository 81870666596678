<template>
    <v-app-bar color="#014ba0" absolute>
      <v-container fluid>

        <div class="top-nav">
          <v-row class="d-flex flex-row justify-start align-center">
            <v-col cols="3" class="img-col d-flex flex-row justify-center align-center"><img src="@/assets/images/logo.png" @click="$router.push({ name: 'home' })"></v-col>
            <v-col cols="5"><input type="search" name="navSearch" id="navSearch" placeholder="search product"></v-col>
            <v-col cols="4">
              <div class="parent d-flex flex-row justify-space-between align-center">
                <div class="available">
                  <span>available 24/ 7 at</span>
                  <br>
                  <strong>(090) 124-3848</strong>
                </div>
                <div class="wishlists d-flex flex-column align-center">
                  <v-icon>mdi-heart</v-icon>
                  <span>wish lists</span>
                </div>
                <div class="wishlists d-flex flex-column align-center">
                  <v-icon>mdi-account</v-icon>
                  <span>sign in</span>
                </div>
                <div class="wishlists d-flex flex-column align-center" :style="`cursor:pointer;pointer-events:${$route.name === 'cartpage' ? 'none' : 'unset'}`" @click="toggleCart">
                  <v-badge location="right top" color="blue" offsetX="-12" :style="`z-index:2;cursor:pointer;pointer-events:${$route.name === 'cartpage' ? 'none' : 'unset'}`" @click="toggleCart" :content="cartItems.length" v-if="cartItems.length"></v-badge>
                  <v-icon>mdi-cart-outline</v-icon>
                  <span>cart</span>
                </div>
              </div> <!-- End parent -->
            </v-col>
          </v-row>
        </div>

        <div class="main-nav">
          <v-row>
            <v-col cols="7" class="col-left">
              <ul class="links d-flex flex-row justify-space-between">
                <li v-for="category in categories" :key="category.title">
                  <router-link :to="{ name: 'categorypage', query: { category: category.route, title: category.title }}">{{ category.title }}</router-link>
                </li>
              </ul>
            </v-col>
            <v-col cols="5" class="col-right d-flex flex-row justify-end align-center" style="gap:0.5rem;">
              <div class="help d-flex flex-row align-center" style="gap:0.1rem;">
                <v-icon>mdi-headset</v-icon>
                <span>help</span>
              </div>|
              <div class="lang d-flex flex-row align-center" style="gap:0.1rem;cursor:pointer;" id="language-btn">
                <img :src="selectedLang[0].icon">
                <span>{{ selectedLang[0].lang }} / {{ selectedLang[0].currency }}</span>
                <v-icon>mdi-chevron-down</v-icon>
                <v-menu activator="#language-btn" @click.stop>
                  <v-list v-model:selected="selectedLang" mandatory>
                    <v-list-item v-for="lang in langs" :key="lang.lang" :value="lang">
                      <v-list-item-title><img :src="lang.icon"> {{ lang.lang }} / {{ lang.currency }}</v-list-item-title>
                    </v-list-item>
                  </v-list>
                </v-menu>
              </div>
            </v-col>
          </v-row>
        </div>

      </v-container>
    </v-app-bar>
</template>

<script>
import { productsModule } from "@/stores/products";
import { mapState } from "pinia";
import { cartStore } from "@/stores/cart";

export default {
  inject: ["Emitter"],
  methods: {
    toggleCart(){
      this.Emitter.emit("toggleCart");
    },
  },
  computed: {
    ...mapState(productsModule, ["categories"]),
    ...mapState(cartStore, ["cartItems"]),
  },
  data: () => ({
    selectedLang: [{
                    icon:require('@/assets/images/england.png'),
                    lang:'EN',
                    currency:'USD',
                   }
                  ],
    langs: [
      {
       icon:require('@/assets/images/england.png'),
       lang:'EN',
       currency:'USD',
      },
      {
       icon:require('@/assets/images/germany.png'),
       lang:'DE',
       currency:'EURO',
      }
    ]
  }),
};
</script>