import "@/assets/style.scss";
import { createApp } from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";

// Pinia Config
import { createPinia } from "pinia";

// Emitter Config
import mitt from "mitt";
const Emitter = mitt();

// Swiper Config
import "swiper/css";
import "swiper/css/navigation";
import "swiper/css/pagination";

// vuetify
import "vuetify/styles";
import { createVuetify } from "vuetify";
import * as components from "vuetify/components";
import * as directives from "vuetify/directives";
import "@mdi/font/css/materialdesignicons.css";

const vuetify = createVuetify({
  components,
  directives,
});


// const loadAllMinJS = () => {
//   const script = document.createElement("script");
//   script.src = "./assets/all.min.js";
//   script.async = true;
//   script.onload = () => {
//     console.log("all.min.js loaded successfully");
//   };
//   document.body.appendChild(script);
// };

// document.addEventListener("DOMContentLoaded", loadAllMinJS);

createApp(App)
 .use(store)
 .use(vuetify)
 .provide("Emitter", Emitter)
 .use(createPinia())
 .use(router)
 .mount("#app");