<template>
  <div class="quick-view">
    <v-dialog v-model="dialog" style="background-color:var(--color-light);" max-width="800" max-height="450">
      <v-icon class="close-icon" @click="dialog = false">mdi-close</v-icon>
      <v-card elevation="0">
        <v-container class="product-container bg-white pt-5 px-6">
         <v-row>
   
           <v-col sm="6" cols="12" class="col-left">
   
             <ul class="allcategoriesproduct d-flex-r-st-c"><li>{{ product.category }}</li></ul>
     
             <div class="all-images d-flex flex-column justify-center align-start">
             
               <div class="big-image">
                 <img :src="product.thumbnail" alt="" id="viewProductImg">
               </div>
   
               <div class="small-images d-flex-r-bt-st">
                 <div class="small-image" v-for="(img, index) in product.images" :key="index"><img :src="img" class="small-img" @click="changeBigImage(img)"></div>
               </div>
     
             </div> <!-- End all-images -->
   
           </v-col> <!-- End col-left -->
   
           <v-col sm="6" cols="12" class="col-right">
             <div class="content d-flex-c-st-st">
               <p>visit the hans store</p>
               <h1>{{ product.title }}</h1>
               <v-rating v-model="product.rating" density="compact" color="yellow-darken-2" half-increments readonly></v-rating>
               <div class="description-block d-flex flex-column justify-start align-start">
                 <p><span>description</span> <v-icon>mdi-note-multiple</v-icon></p>
                 <p>{{ product.description }}</p>
               </div>
               <p><span>brand:</span> {{ product.brand }}</p>
               <p><span>availability:</span> {{ product.stock > 0 ? 'In stock' : 'Out of stock' }}</p>
               <p><span>In stock:</span> {{ product.stock }}</p>
                           
               <div class="product-price d-flex flex-row justify-start align-center"> <!-- price -->
                 <strong class="oldprice">${{ product.price }}</strong>
                 <strong class="price">${{ Math.ceil(product.price - product.price * (product.discountPercentage / 100)) }}</strong>
               </div>
               
               <div v-if="product.sizes && product.sizes.length > 0" class="size-block d-flex flex-column justify-start align-start" style="gap:0.5rem;">
                 <p><span>size:</span> {{ product.selectedSize }}</p>
                 <div class="sizes d-flex flex-row justify-start align-start" style="gap:1rem;">
                   <span v-for="size in product.sizes" :key="size" :class="{ 'selected-size': size === product.selectedSize }" @click="selectSize(size)">{{ size }}</span>
                 </div>
               </div>
   
               <div v-if="product.colors && product.colors.length > 0" class="color-block d-flex flex-column justify-start align-start" style="gap:0.5rem;">
                 <p><span>Color:</span> {{ product.selectedColor }}</p>
                 <div class="colors d-flex flex-row justify-start align-start" style="gap:1rem;">
                   <span v-for="(color, index) in product.colors" :key="index" class="color-circle" :style="{ backgroundColor: color }" @click="selectColor(color)"></span>
                 </div>
               </div>
   
               <div class="product-quantity-block d-flex-c-st-st" style="gap:0.4rem;">
                 <p><span>quantity:</span></p>
                 <div class="product-quantity d-flex-r-st-c">
                   <button type="button" class="pro-quantity-btn d-flex-r-c-c" @click="decrementQuantity"><v-icon>mdi-minus</v-icon></button>
                   <span id="pro-quantity-no">{{ quantity }}</span>
                   <button type="button" class="pro-quantity-btn d-flex-r-c-c" @click="incrementQuantity"><v-icon>mdi-plus</v-icon></button>
                 </div>
                 <p style="font-size:0.9rem;"><span>subtotal:</span> ${{ calculateSubtotal }}</p>
               </div>

               <v-btn type="button" variant="outlined" density="compact" class="btn" @click="addToCart(product)" :loading="btnLoading">Add To Card</v-btn>
               <router-link to=""><p>see all mobiles</p></router-link>
             </div> <!-- End content -->
           </v-col> <!-- End col-right -->
   
         </v-row>
        </v-container>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import { cartStore } from "@/stores/cart";
import { mapActions } from "pinia";

export default {
  inject: ["Emitter"],
  methods: {
    ...mapActions(cartStore, ["addItem"]),
    addToCart(item){
      item.quantity = this.quantity;
      this.btnLoading = true;
      setTimeout(()=> {
        this.btnLoading = false;
        this.addItem(item);
        this.Emitter.emit("openCart");
        this.Emitter.emit("showMsg", item.title);
        this.dialog = false;
      }, 1000);
    },
    changeBigImage(imageUrl) {
      document.getElementById('viewProductImg').src = imageUrl;
    },
    selectSize(size) {
      this.product.selectedSize = size;
    },
    selectColor(color) {
      this.product.selectedColor = color;
    },
    decrementQuantity() {
      if (this.quantity > 1) {
          this.quantity--;
      }
    },
    incrementQuantity() {
      if (this.quantity < this.product.stock) {
          this.quantity++;
      }
    }
  },
  computed: {
    calculateSubtotal() {
      return Math.ceil(this.product.price - this.product.price * (this.product.discountPercentage / 100)) * this.quantity;
    }
  },
  data() {
    return {
      quantity: 1,
      dialog: false,
      product: "",
      loading:false,
      btnLoading:false,
    };
  },
  mounted(){
    this.Emitter.on('openQuickView', (data) => {
      this.loading = true;
      this.product = data;
      this.dialog = true;
      setTimeout(()=>{
        this.loading = false;
      }, 1000);
    });
  },
}
</script>

<style lang="scss" scoped>
.close-icon {position:absolute !important;
             top:3.5rem;
             right:-0.7rem;
             padding:0.8rem 0.9rem;
             background-color:var(--black2);
             color:var(--white2) !important;
             font-size:1.1rem !important;
             border-radius:2px;
             z-index:10;}

.v-card {margin-top:4rem;
         
            &::-webkit-scrollbar-track {width:8px;
                                        background-color:var(--gray8);}
   
            &::-webkit-scrollbar {width:8px;}
            &::-webkit-scrollbar-thumb {background-image:linear-gradient(125deg, var(--shin-blue3) 0%, var(--violet6) 100%);
                                        border-radius:10px;}
        }

    .product-container {

        .col-left {
            .allcategoriesproduct {margin-bottom:1rem;
                                   gap:0.2rem;

                .catlink {color:var(--gray-color2);
                          font-size:0.9rem;
                          font-weight:bold;}
            }
            .all-images {gap:1rem;

                .small-images {width:100%;
                               gap:0.5rem;
                               flex-wrap:wrap;

                  .small-image {flex:1 1 2rem;
                                height:5rem;
                                cursor:pointer;
                                padding:0.3rem;
                                border:1px solid rgb(204, 202, 202);

                      img {width:100%;
                           height:100%;
                           object-fit:cover;}
                  }

                }

                .big-image img {width:100%;
                                max-height:36rem;}
            }
        }

        .col-right {padding:2rem;

            .content {width:100%;
                      gap:0.5rem;
                      padding-top:0.8rem;

                h1 {font-weight:800;}

                p {font-size:1rem;

                  span {font-weight:bold;}

                  .v-icon {font-size:1.2rem;
                           margin:0 !important;}

                }

                .size-block {
                  .sizes {
                    span {cursor:pointer;
                          border:1.9px solid var(--black2);
                          padding:0.3rem 0.8rem;}

                    .selected-size {border-width:2px;}
                  }
                }

                .color-block {margin:0.4rem 0 0.5rem;
                  .color-circle {width:2.3rem;
                                 height:2.3rem;
                                 border-radius:50%;
                                 cursor:pointer;
                                 border:1px solid var(--gray2);
                                 box-shadow:inset 0 0 0 3px var(--gray9);}
                }

                .product-price {margin:0;
                                gap:1rem;

                    .price {font-size:1.3rem;
                            color:var(--orange-red1);}
                }

                .product-quantity {width:fit-content;
                                   margin:0;
                                   gap:0.5rem;
                                   border:1px solid var(--gray3);

                    .pro-quantity-btn {width:2.1rem;
                                       height:2.1rem;
                                       font-size:1rem;
                                       color:var(--gray2);
                                       background-color:transparent;
                                       cursor:pointer;}

                    #pro-quantity-no {font-size:1rem;
                                      margin:0 0.5rem;
                                      padding:0;
                                      color:var(--gray1);}

                    .pro-quantity-btn:first-child {border-right:1px solid var(--gray4);}
                    .pro-quantity-btn:nth-child(3) {border-left:1px solid var(--gray4);}

                }

                .btn {margin-top:0.8rem;
                      width:80%;
                      border-radius:2rem;}

                @media(max-width:510px){.btn {width:100%;}}
  
            }
        }

    }

</style>